// Fonts Defaults
.roboto-regular {
	font-family: "Roboto", sans-serif;
	font-weight: 400;
	font-style: normal;
}

$defaultFont : 'Roboto',
Arial,
Helvetica,
sans-serif;
$iconFont : 'Font Awesome 5 Pro';