@import './assets/mixins';
@import './assets/variables';

// General
body { margin: 0; padding: 0; font-family: $defaultFont; }

// HTML5 Standard
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section { display: block; }

// Box model standard
*, *:before, *:after { box-sizing: border-box; }

.App { margin: 0; padding: 0; }