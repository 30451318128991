.Home {
    display: grid;
    align-items: center;
	align-content: center;
    text-align: center;
	height: 100vh;
	width: 100%;
	max-width: 600px;
	margin: 0 auto;

	p {
		text-align: left;
	}
}